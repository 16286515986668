/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { Link as Link1, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { logoutUser, changeNickname } from "../../actions/authActions";
import jwt_decode from "jwt-decode";

import { getUserCoins } from "../../actions/games";
import {
  loginWithFacebook,
  getloginUserHighscores,
} from "../../actions/authActions";
import PropTypes from "prop-types";
import LoginModal from "../../components/auth/loginModal";
import Register from "../../components/auth/register";
import $ from "jquery";
import axios from "axios";
import {
  getCurrentUser,
  isAuthenticated,
  changePassword,
  changeProfileImg,
  registerWithWallet,
  loginJwtWithWallet,
  userTScoresUpdateIo,
} from "../../actions/authActions";
import { doubleUserCoins } from "../../actions/games";
import Spinner from "../Spinner";
import {
  getTournaments,
  getAllTournaments,
  sweepStake,
  getAllSweepStake,
  getMonthlySweepStakeWinners,
  getUserSweepstakePosition,
  getSweepStakeWinner,
  updateGamesIo,
  updateTournamentsIo,
  updateSweepIo,
  sweepstakePosSocket,
} from "../../actions/sweepStake";
import {
  getMonthlyTournamentWinners,
  getTournamentPositions,
  getUserTournaments,
  setOtherTournamentEnded,
  updateTournamentSeen,
  getTournamentSeen,
  tournamentPosSocket,
} from "../../actions/tournaments";
import dateHelper from "../../helpers/dateHelper";
import { getHighscores, setUserMoney } from "../../actions";
import { getWinnings } from "../../actions";

import { ethers } from "ethers";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import CoinbaseWalletSDK from "@coinbase/wallet-sdk";
import moment from "moment";
import jstz from "jstimezonedetect";
import HighscoreModal from "../../components/modals/highscoreModal";
import socket from "../../helpers/socket";
import TournamentEnded from "../../components/modals/TournamentEnded";
import SweepstakeEnded from "../../components/modals/SweepstakeEnded";
import CountryNotAvailable from "../../components/modals/CountryNotAvailable";
import SubscriptionResponse from "../../components/modals/subscriptionResponse";
import WithdrawMoney from "../../components/modals/WithdrawMoney";
import WithdrawAmount from "../../components/modals/WithdrawAmount";
import WithdrawalSuccess from "../../components/modals/WithdrawalSuccess";
import WithdrawHistory from "../../components/modals/withdrawHistory";
import {
  cancelTransaction,
  getTransactionHistory,
} from "../../actions/paymentActions";
import MoneyBack from "../../components/modals/MoneyBack";

const tz = jstz.determine().name();
const TIMEZONE = dateHelper.getTimeZone(tz);
let aiptag = window.aiptag || { cmd: [] };

class Header extends Component {
  web3Modal;

  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      filteredSweep: false,
      name: "",
      input: {},
      errors: {},
      isValid: true,
      sweepFinish: false,
      upcomingTournamentId: "",
      upcomingSweepId: "",
      nextSweepstakeFinishDate: 0,
    };

    this.web3Modal = new Web3Modal({
      cacheProvider: false,
      providerOptions: {
        walletconnect: {
          package: WalletConnectProvider,
          options: {
            infuraId: process.env.REACT_APP_INFURA_ID,
          },
        },
        coinbasewallet: {
          package: CoinbaseWalletSDK,
          options: {
            appName: "Web3Modal Example",
            infuraId: process.env.REACT_APP_INFURA_ID,
          },
        },
      },
    });

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.registerWallet = this.registerWallet.bind(this);
    this.loginWallet = this.loginWallet.bind(this);

    this.highscoresClick = this.highscoresClick.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.sweepstake !== this.props.sweepstake &&
      this.props.sweepstake.length > 0
    ) {
      this.setState({ filteredSweep: this.props.sweepstake[0] });
    }
  }

  async componentDidMount() {
    if (this.props.sweepstake.length > 0) {
      this.setState({ filteredSweep: this.props.sweepstake[0] });
    }
    socket.on("connect", () => {
      console.log("Socket Connected");
      socket.emit("join", { token: this.props.user._id });
    });
    socket.on("disconnect", () => {
      console.log("Socket Got disconnected!");
    });
    socket.on("sweepstake-ended-modal", (data) => {
      this.setState({ filteredSweep: false });
      // window.sUnslick('#sweepstake-ended')
      // this.props.updateSweepIoDispatch([]);
      this.props.sweepstakePosSocketDispatch(data);
      if (
        this.props.router.pathname === "/gameplay" ||
        this.props.router.pathname === "/tournament"
      ) {
        setTimeout(() => {
          document.getElementById("myIframe").contentWindow.sound(false);
          document.getElementById("myIframe").contentWindow.pause(true);
        }, 1000);
      }
      // hide end sweepstake Modal
      // window.sweepstakeEndedModale('#sweepstake-ended')
    });

    socket.on("tournament-ended-modal", (data) => {
      // window.tUnslick("#tournament-win2")
      if (this.props.router.pathname === "/") {
        window.myFunct();
      }
      this.props.tournamentPosSocketDispatch(data);
      if (
        this.props.router.pathname === "/gameplay" ||
        this.props.router.pathname === "/tournament"
      ) {
        let tid = this.props.router.query.t_id
          ? this.props.router.query.t_id
          : "321";
        let endedTid = data.userPosition
          ? data.userPosition.tournamentId
          : "123";
        if (tid === endedTid) {
          return document.getElementById("myIframe").contentWindow.GameOver_();
        } else {
          setTimeout(() => {
            document.getElementById("myIframe").contentWindow.sound(false);
            document.getElementById("myIframe").contentWindow.pause(true);
          }, 1000);
        }
      }
      // hide end tournament Modal
      // window.tSEndedModale("#tournament-win2");
    });

    socket.on("my-tournament-scores", (data) => {
      this.props.userTScoresUpdateIoDispatch(data);
    });
    socket.on("tournament-updates", (data) => {
      this.props.updateTournamentsIoDispatch(data);
    });

    this.setState({ nextSweepstakeFinishDate: this.props.nextSweepstakeDate });

    socket.on("gameUpdate", async (data) => {
      await window.unSlicker();
      await this.props.updateGamesIoDispatch(data);
    });

    const {
      props: { loginWithFacebookDispatch, history },
    } = this;

    $("#withdraw-btn").on("click", () => {
      alert("Coming soon...");
      // window.myFunct()
      // window.openModale('#withdraw-money')
    });

    $("#wallet-history").on("click", () => {
      window.openModaleNoCLose("#withdraw-history");
      // debugger
      this.props.getTransactionHistoryDispatch();
    });
    $("#logout-btn").click(this.handleLogout);
    $("#img-input").change(this.handleUpload);
    $(".name-form").change(this.onChange);
    $("body").on("click", "#winners-register", this.closeFancybox);
    $("#changenick-submit").click(this.handleNickChange);
    $("#highButton").click(() => {
      this.highscoresClick();
      window.myFunct();
    });
    $("#collect-money").click(this.addUserWinning);
    $("#winning").click(() => {
      this.myWinnigsClick();
      window.myFunct();
    });
    // $(".my_winning_popup_click").on('click',(e)=>{debugger})
    $("#pending-button").on("click", () => this.cancelPending());
    $("#how-to-win1, #how-to-win2").on("click", () => {
      if (
        this.props.router.pathname === "/gameplay" ||
        this.props.router.pathname === "/tournament"
      ) {
        document.getElementById("myIframe").contentWindow.sound(false);
        document.getElementById("myIframe").contentWindow.pause(true);
      }
    });

    $("#how-to-win-close1, #myProf2").on("click", () => {
      if (
        this.props.router.pathname === "/gameplay" ||
        this.props.router.pathname === "/tournament"
      ) {
        document.getElementById("myIframe").contentWindow.sound(true);
        document.getElementById("myIframe").contentWindow.pause(false);
      }
    });
    $("#download-app-btn").on("click", () => {
      // debugger
      this.props.history.push("/#download-app");
      setTimeout(() => {
        alert("Coming soon.");
        // $('html, body').animate({
        //   scrollTop: $("#download-app").length > 0 ? $("#download-app").offset().top - 130 : 0
        // }, 500);
      }, 500);
    });
    $("#play-redirect-sweep, #play-redirect-sweep1, #play-redirect-sweep2").on(
      "click",
      async () => {
        window.myFunct2();
        window.location.href = "/#all-games";
        setTimeout(() => {
          $("html, body").animate(
            {
              scrollTop:
                $("#all-games").length > 0 ? $("#all-games").offset().top : 0,
            },
            500
          );
        }, 600);
      }
    );
    $("#play-redirect-tour, #play-redirect-tour1, #play-redirect-tour2").on(
      "click",
      async () => {
        window.myFunct2();
        window.location.href = "/#tournament-scroll";
        setTimeout(() => {
          $("html, body").animate(
            {
              scrollTop:
                $("#tournament-scroll").length > 0
                  ? $("#tournament-scroll").offset().top
                  : 0,
            },
            500
          );
        }, 600);
      }
    );
    this.props.getMonthlySweepStakeWinnersDispatch();
    this.props.getMonthlyTournamentWinnersDispatch();
    if (localStorage.jwtToken) {
      this.props.isAuthenticatedDispatch();
      this.props.fetchCurrentUserDispatch();
      // this.props.getUserTournamentsDispatch();
      this.props.getloginUserHighscoresDispatch();
      this.props.getTournamentSeenDispatch();
      aiptag.cmd.player = aiptag.cmd.player || [];

      //CMP tool settings
      aiptag.cmp = {
        show: true,
        position: "centered", //centered, bottom
        button: true,
        buttonText: "Privacy settings",
        buttonPosition: "bottom-left", //bottom-left, bottom-right, top-left, top-right
      };
    }

    $("#changePassword_btn").click(this.handleSubmit);
    $(".change_Password").change(this.handleChange);

    // this.props.getUserCoins()
    this.props.sweepStakeDispatch();
    this.props.getAllSweepStakeDispatch();
    this.props.fetchTournamentsDispatch();
    this.props.fetchAllTournamentsDispatch();
    this.props.fetchCurrentUserDispatch();
    if (window.location.href.includes("access_token")) {
      const fbToken = window.location.href.split("access_token=").pop();
      const fbApi = `https://graph.facebook.com/me?fields=email,name,picture.width(120).height(120),id&access_token=${fbToken}`;

      const fbResp = await axios.get(fbApi);
      if (fbResp.status === 200) {
        const fbPayload = {
          name: fbResp.data.name,
          facebookId: fbResp.data.id,
          email: fbResp.data.email,
          imageUrl: fbResp.data.picture.data.url,
        };
        loginWithFacebookDispatch(fbPayload, history);
      }
    }
  }

  addUserWinning = () => {
    // debugger
    let winning = this.props.t_you ? this.props.t_you.money : 0;
    if (winning > 0) {
      let usermoney = this.props.auth.user ? this.props.auth.user.money : 0;
      winning = Number(winning) + Number(usermoney);
      // debugger
      this.props.setUserMoneyDispatch(winning);
    }
  };

  highscoresClick = (user) => {
    // debugger
    if (localStorage.jwtToken) {
      const thisuser = jwt_decode(localStorage.jwtToken);
      // debugger
      this.props.getHighscoresDispatch({ userId: thisuser.userId });
    }
  };

  cancelPending = () => {
    // debugger
    if (
      window.confirm("Do You Want to cancel Pending Transactions?") === true
    ) {
      this.props.cancelTransactionDispatch();
    }
  };

  openAccountModale = () => {
    if (
      this.props.router.pathname === "/gameplay" ||
      this.props.router.pathname === "/tournament"
    ) {
      document.getElementById("myIframe").contentWindow.sound(false);
      document.getElementById("myIframe").contentWindow.pause(true);
      this.props.history.push("/#account", { state: "account" });
    } else {
      window.openModaleNoCLose("#popup-account");
    }
  };

  myWinnigsClick = (user) => {
    if (localStorage.jwtToken) {
      const thisuser = jwt_decode(localStorage.jwtToken);
      this.props.getWinningsDispatch({ userId: thisuser.userId });
    }
  };

  getActiveTournaments = () => {
    const {
      props: { tournaments },
    } = this;
    const current = moment().tz(TIMEZONE.value);
    const newArr = tournaments.filter(function (obj) {
      if (
        (moment(obj.finishDate).tz(TIMEZONE.value).isSame(current) ||
          moment(obj.finishDate).tz(TIMEZONE.value) > current) &&
        (moment(obj.startDate).tz(TIMEZONE.value) < current ||
          moment(obj.startDate).tz(TIMEZONE.value).isSame(current))
      ) {
        return true;
      }
    });
    return newArr;
  };

  closeFancybox = () => {
    window.myFunct();
    window.openModale("#register-soc");
  };

  onLogoutClick = (event) => {
    // debugger
    event.preventDefault();
    this.props.logoutUserDispatch(this.props.history);
  };

  handleLogout = async (e) => {
    // debugger
    e.preventDefault();
    await this.web3Modal.clearCachedProvider();
    if (
      this.provider?.disconnect &&
      typeof this.provider.disconnect === "function"
    ) {
      await this.provider.disconnect();
    }
    this.props.logoutUserDispatch(this.props.history);
  };

  handleNickChange = (event) => {
    event.preventDefault();
    const data = {
      name: this.state.name,
    };
    this.props.changeNickname(data);
  };

  onChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    this.setState({ [name]: value });
  };

  validateField(fieldName, value) {
    let nameEmpty = this.state.nameEmpty;
    let errors = this.state.errors;
    let passwordlength = "";
    // debugger
    switch (fieldName) {
      case "name":
        nameEmpty = value !== "" ? "" : "Provide Name for user";
        break;
      case "oldPassword":
        passwordlength = value.length >= 6;
        errors["oldPassword"] = passwordlength
          ? ""
          : " Password is too short. must be 6 characters long";

        break;
      case "newPassword":
        passwordlength = value.length >= 6;
        errors["newPassword"] = passwordlength
          ? ""
          : " Password is too short. must be 6 characters long";

        break;
      case "confirm_password":
        passwordlength = value.length >= 6;
        errors["confirm_password"] = passwordlength
          ? ""
          : " Password is too short. must be 6 characters long";
        if (
          typeof this.state.input.newPassword !== undefined &&
          typeof value !== undefined
        ) {
          errors["confirm_password"] =
            this.state.input.newPassword === value
              ? ""
              : "Passwords don't match";
        }
        break;
      default:
        break;
    }
    this.setState({
      nameEmpty,
      errors: errors,
    });
  }

  // Change Password Secton
  handleSubmit(event) {
    event.preventDefault();
    // console.log("this is the state", this.state.input);

    if (this.state.input.newPassword === this.state.input.confirm_password) {
      // console.log("this is the state", this.state.input);

      let input = this.state.input;
      const oldPassword = input["oldPassword"];
      const newPassword = input["newPassword"];
      const newPassReq = {
        oldPassword,
        newPassword,
      };
      this.props.changePasswordDispatch(newPassReq);
      // console.log("this is the new pass req", newPassReq);
    }
  }

  handleChange(event) {
    let name = event.target.name;
    let value = event.target.value;
    this.setState(
      (prevState) => ({
        input: {
          ...prevState.input,
          [name]: value,
        },
      }),
      () => {
        this.validateField(name, value);
      }
    );
  }

  handleUpload = (event) => {
    event.preventDefault();
    if (event.target.files && event.target.files[0]) {
      let newFile = event.target.files[0];
      const fsize = newFile.size;
      const file = Math.round(fsize / 1024);
      if (file >= 5121) {
        alert("File too Big, please select a file less than 5MB");
      } else {
        this.props.changeProfileImgDispatch(newFile);
      }
    }
  };

  registerWallet = async () => {
    window.myFunct();
    try {
      const web3ModalProvider = await this.web3Modal.connect();
      const provider = new ethers.providers.Web3Provider(web3ModalProvider);
      const signer = await provider.getSigner(0);
      const address = await signer.getAddress();
      const newUser = { image: null, address: address };
      this.props.registerWithWallet(newUser, this.props.history);
    } catch (error) {
      console.error("wallet connect failed:", error);
    }
  };

  loginWallet = async () => {
    window.myFunct();
    try {
      const web3ModalProvider = await this.web3Modal.connect();
      const provider = new ethers.providers.Web3Provider(web3ModalProvider);
      const signer = await provider.getSigner(0);
      const address = await signer.getAddress();
      this.props.loginJwtWithWallet({ address: address }, this.props.history);
    } catch (error) {
      console.error("wallet connect failed:", error);
    }
  };

  render() {
    const {
      props: {
        user,
        sweepstake,
        errors,
        loading,
        highscores,
        winnings,
        countryAllowed,
        paymentStatus,
        tournamentEnded,
        sweepstakeEnded,
      },
      state: { filteredSweep },
    } = this;
    const self = this;
    let curr = moment();
    sweepstake.sort((a, b) =>
      a.finishDate > b.finishDate ? 1 : b.finishDate > a.finishDate ? -1 : 0
    );
    let filteredsweepstake = sweepstake.filter((el) =>
      moment(el.finishDate).isAfter(curr)
    );

    let c = 0;
    let currentSweepTime = "";
    filteredsweepstake.forEach((el) => {
      c++;
      if (c <= 1) {
        currentSweepTime = el;
        return;
      }
    });

    let sum = currentSweepTime.totalJackpotAmount ?? 0;
    // debugger
    return (
      <div className="sticky-header">
        {countryAllowed === false ? (
          <>
            <CountryNotAvailable /> <SubscriptionResponse />{" "}
          </>
        ) : (
          ""
        )}
        {loading && <Spinner />}
        <SweepstakeEnded />
        <WithdrawHistory />
        <TournamentEnded />
        <HighscoreModal />
        <WithdrawAmount user={user} />
        <WithdrawMoney user={user} />
        <MoneyBack />
        {paymentStatus ? <WithdrawalSuccess /> : ""}
        <header>
          <div className="header-row">
            <div
              className="header-logo"
              onClick={(e) => {
                window.scrollTo(0, 0);
              }}
            >
              <a href="/">
                <img src="images/logo.png" alt="image" />
              </a>
            </div>
            <div id="preroll"></div>
            <nav className="header-nav">
              {1 == 2 && this.props.auth && this.props.auth.isAuthenticated ? (
                <>
                  <div className="header-menu" style={{ marginLeft: "95%" }}>
                    <ul>
                      <li>
                        <Link1 to={"/"} onClick={this.onLogoutClick}>
                          Logout
                        </Link1>
                      </li>
                    </ul>
                  </div>
                </>
              ) : (
                <>
                  <ul className="header-menu">
                    <li id="play-redirect-sweep1">
                      <a>SWEEPSTAKE</a>
                    </li>
                    <li id="play-redirect-tour1">
                      <a>Tournaments</a>
                    </li>
                    <li
                      onClick={() => {
                        window.scrollTo(0, 0);
                        window.location.href = "/winner";
                      }}
                      style={{ display: "flex" }}
                    >
                      {(tournamentEnded?.userPosition ||
                        sweepstakeEnded?.userPositions) && (
                        <img
                          className="notify-icon"
                          src="images/icons/notify.png"
                          alt="notify"
                        />
                      )}
                      <a>Winners</a>
                    </li>
                    <li>
                      <a
                        id="how-to-win1"
                        onClick={() => window.openModaleNoCLose("#cash-prizes")}
                      >
                        HOW TO WIN
                      </a>
                    </li>
                  </ul>
                  <div className="header-btn">
                    <a id="download-app-btn" className="btn btn__blue">
                      Download app
                    </a>
                    {!localStorage.jwtToken && (
                      <a
                        href=""
                        data-fancybox="register-soc"
                        data-src="#register-soc"
                        className="btn btn__trans btn__icon"
                      >
                        Register now <i className="icon-lock"></i>
                      </a>
                    )}
                    {!localStorage.jwtToken && (
                      <a
                        id="jkl"
                        href=""
                        data-fancybox="login-modal"
                        data-src="#login-modal"
                        className="btn btn__gradient btn__icon"
                      >
                        login <i className="icon-lock"></i>
                      </a>
                    )}
                  </div>

                  {localStorage.jwtToken && (
                    <div className="header-account">
                      <div className="account-money coins-add-point">
                        <img src="images/icons/coin-icon.png" alt="" />
                        <p id="coins">{user ? user.coins : 0}</p>
                      </div>
                      <div className="account-money money-add-point">
                        <img src="images/icons/money-icon.png" alt="" />
                        <p id="header-money">$PLY {user ? user.money : 0}</p>
                      </div>
                      <div
                        onClick={() => this.openAccountModale()}
                        className="header-user"
                      >
                        {user.address && <p>{user.address}</p>}
                        <img
                          src={
                            user && user.imageUrl
                              ? user.imageUrl
                              : "assets/images/games/defaultProfile.jpg"
                          }
                          alt=""
                        />
                      </div>
                    </div>
                  )}
                </>
              )}
            </nav>
            <div className="burger">
              <span></span>
            </div>

            <Register
              history={this.props.history}
              regWallet={() => this.registerWallet()}
            />
            <LoginModal
              history={this.props.history}
              logWallet={() => this.loginWallet()}
            />

            <div>
              <div
                id="popup-account"
                style={{ display: "none" }}
                className="popup-style popup-style__text-center popup-style__pt popup-style__w600"
              >
                <p id="myProf2">
                  <button
                    type="button"
                    data-fancybox-close
                    className="fancybox-close-small fancybox-button"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1"
                      viewBox="0 0 24 24"
                    >
                      <path d="M13 12l5-5-1-1-5 5-5-5-1 1 5 5-5 5 1 1 5-5 5 5 1-1z"></path>
                    </svg>
                  </button>
                </p>
                <div className="popup-box">
                  <div className="popup-top">
                    <div className="popup-top__img popup-top__img--picture">
                      <input
                        id="img-input"
                        onChange={this.handleUpload}
                        type="file"
                        accept="image/png, image/jpeg"
                      />
                      <img
                        src={
                          user && user.imageUrl
                            ? user.imageUrl
                            : "assets/images/games/defaultProfile.jpg"
                        }
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="popup-content">
                    <div className="popup-account__nickaname">
                      <div className="change-nickname">
                        <span
                          data-fancybox="nickname"
                          data-src="#new-nickname"
                          className="btn-change__nickname"
                        >
                          <i className="icon-pen-angled"></i>
                        </span>
                        <input
                          type="text"
                          value={user ? user.name : ""}
                          disabled
                        />
                      </div>
                      <p>{user ? user.email ?? user.address : ""}</p>
                    </div>
                    <h3 className="title-balance">BALANCE</h3>
                    <div className="wrap-balance">
                      <div id="lucky_coins" className="box-balance-col">
                        <div className="box-balance box-balance__coin">
                          <img
                            src="images/icons/coin-no-shadow-icon.png"
                            alt=""
                          />
                          <h4>LUCKY COINS</h4>
                          <p>
                            {user ? user.coins : this.props.userCoins}
                            <i className="icon-plus"></i>
                          </p>
                        </div>
                      </div>
                      <div className="box-balance-col">
                        <div
                          id="lucky_money"
                          className="box-balance box-balance__money"
                        >
                          <img src="images/icons/cash-icon.png" alt="" />
                          <h4>MONEY</h4>
                          <p>
                            $PLY {user && user.money ? user.money : ""}
                            <i className="icon-plus"></i>
                          </p>
                        </div>
                        <p className="balance-note">
                          ( Pending - $PLY{" "}
                          {user.pendingBalance ? user.pendingBalance : 0.0} ){" "}
                          <button id="pending-button" />
                        </p>
                      </div>
                    </div>
                    <div className="popup-account__buttons">
                      <div>
                        <a
                          className="hover-highscores btn btn__gray"
                          href="#"
                          id="highButton"
                          onClick={(e) => this.highscoresClick(e, user)}
                        >
                          HIGH SCORES
                        </a>
                        <a
                          className="btn btn__gray hover-mywinning"
                          href="#"
                          id="winning"
                          onClick={(e) => this.myWinnigsClick(e, user)}
                          style={{ float: "right" }}
                        >
                          MY WINNINGS
                        </a>
                      </div>
                      <a id="withdraw-btn" className="btn btn__gradient">
                        WITHDRAW
                      </a>
                    </div>
                    <div className="popup-account__links">
                      <a id="wallet-history">WALLET HISTORY</a>
                      <a
                        href="#"
                        data-fancybox="change-password"
                        data-src="#change-password"
                      >
                        CHANGE PASSWORD
                      </a>
                    </div>
                    <p className="link-bottom">
                      <a
                        id="logout-btn"
                        onClick={(e) => self.handleLogout(e)}
                        href="#"
                      >
                        LOG OUT
                      </a>
                    </p>
                  </div>
                </div>
              </div>

              {/* <!--popup change password--> */}
              <div
                id="change-password"
                style={{ display: "none" }}
                className="popup-style popup-style__text-center popup-style__w600"
              >
                <p>
                  <button
                    type="button"
                    data-fancybox-close
                    className="fancybox-close-small fancybox-button"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1"
                      viewBox="0 0 24 24"
                    >
                      <path d="M13 12l5-5-1-1-5 5-5-5-1 1 5 5-5 5 1 1 5-5 5 5 1-1z"></path>
                    </svg>
                  </button>
                </p>
                <div className="popup-box">
                  <div className="popup-content">
                    <h2 className="popup-title popup-title__f45">
                      CHANGE PASSWORD
                    </h2>
                    <div className="style-form register-form">
                      <form action="">
                        <div className="field field-icon">
                          <i className="icon-lock"></i>
                          <input
                            className="change_Password"
                            id="password"
                            name="oldPassword"
                            value={this.state.input.oldPassword}
                            type="password"
                            placeholder="Current password"
                          />
                          <span className="toggle-password icon-eye"></span>
                        </div>
                        <div style={{ color: "red" }}>
                          {this.state.errors.oldPassword || errors}
                        </div>
                        <div className="field field-icon">
                          <i className="icon-lock"></i>
                          <input
                            className="change_Password"
                            id="password"
                            name="newPassword"
                            value={this.state.input.newPassword}
                            type="password"
                            placeholder="New password"
                          />
                          <span className="toggle-password icon-eye"></span>
                        </div>
                        <div style={{ color: "red" }}>
                          {this.state.errors.newPassword}
                        </div>
                        <div className="field field-icon">
                          <i className="icon-lock"></i>
                          <input
                            className="change_Password"
                            id="confirm_password"
                            name="confirm_password"
                            value={this.state.input.confirm_password}
                            type="password"
                            placeholder="Confirm password"
                          />
                          <span className="toggle-password icon-eye"></span>
                        </div>
                        <div style={{ color: "red" }}>
                          {this.state.errors.confirm_password}
                        </div>
                        <button
                          onClick={(e) => self.handleSubmit(e)}
                          id="changePassword_btn"
                          className="btn btn__blue btn__icon--r"
                          type="submit"
                        >
                          confirm
                          <i className="icon-arrow-right"></i>
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!--popup change password  END --> */}

              {/* nickname popup */}
              <div
                id="new-nickname"
                style={{ display: "none" }}
                className="popup-style popup-style__text-center popup-style__w600"
              >
                <div className="popup-box">
                  <div className="popup-content">
                    <h2 className="popup-title popup-title__f45">
                      new nickname
                    </h2>
                    <div className="style-form register-form">
                      <form action="">
                        <div className="field">
                          <input
                            id="nick-form"
                            className="name-form"
                            type="text"
                            name="name"
                            placeholder="Enter nickname"
                          />
                          {this.state.nameEmpty && (
                            <span style={{ color: "red" }}>
                              {this.state.nameEmpty}
                            </span>
                          )}
                        </div>
                        <button
                          onClick={(e) => self.handleNickChange(e)}
                          id="changenick-submit"
                          className="btn btn__blue btn__icon--r"
                          type="submit"
                        >
                          confirm
                          <i className="icon-arrow-right"></i>
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              {/* nickname popup */}
            </div>

            <div
              id="beta-testers-modal"
              style={{ display: "none" }}
              onClick={() => console.log("========")}
              className="popup-style popup-style__text-center popup-style__pt popup-style__w600"
            >
              <div className="popup-box">
                <div className="popup-top">
                  <div className="popup-join_beta__img">
                    <div className="popup-top__img--picture">
                      <img src="assets/images/playtoo.png" alt="" />
                    </div>
                  </div>
                </div>
                <div className="popup-content">
                  <div className="popup-account__nickaname">
                    <h3 className="title-balance">
                      Playtoo <span style={{ color: "#0BC8C7" }}>BETA</span>{" "}
                      Version
                    </h3>
                    <p>
                      Here you can find just a taste of Playtoo and the games
                      published here are Test Games.
                    </p>
                    <br />
                    <p>
                      The final version with Tournaments, 1VS1 and Multiplayer
                      Match will be published before the listing of the $PLT
                      token. Have fun!!
                    </p>
                  </div>

                  <div className="popup-account__buttons">
                    <a
                      data-fancybox-close
                      className="btn btn__join_beta_testing"
                      style={{ width: "100%" }}
                    >
                      I GOT IT, TEST NOW
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div
              id="cash-prizes"
              style={{ display: "none" }}
              className="popup-style popup-style__w900"
            >
              <p id="how-to-win-close1">
                <button
                  type="button"
                  data-fancybox-close
                  className="fancybox-close-small fancybox-button"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1"
                    viewBox="0 0 24 24"
                  >
                    <path d="M13 12l5-5-1-1-5 5-5-5-1 1 5 5-5 5 1 1 5-5 5 5 1-1z"></path>
                  </svg>
                </button>
              </p>
              <div className="popup-box">
                <div className="popup-content cash-prizes tab-wrap">
                  <h2 className="popup-title popup-title__f45">
                    $PLY prizes every day PLAY FOR <span>FREE</span>
                  </h2>
                  <ul className="tab-menu table-menu">
                    <li id="htw_sweep" className="active">
                      <span>SWEEPSTAKES</span>
                    </li>
                    <li id="htw_tour">
                      <span>TOURNAMENTS</span>
                    </li>
                  </ul>
                  <div className="tab-content">
                    <div className="cash-prizes__row">
                      <div className="cash-prizes__l">
                        <img src="images/other/cash-img-1.png" alt="" />
                      </div>
                      <div className="cash-prizes__r">
                        <div className="cash-prizes__text">
                          <h2>FEELING LUCKY?</h2>
                          <h3>JOIN THE SWEEPSTAKES</h3>
                          <ul>
                            <li>Play games and earn “Lucky Coins”</li>
                            <li>
                              The more Lucky Coin you have, the more chances you
                              have to win our sweepstakes.
                            </li>
                          </ul>
                        </div>
                        <div className="active-prize">
                          <div className="active-prize__text">
                            <h3>ACTIVE PRIZE POOL</h3>
                            <div className="popup-timer popup-timer__mb25">
                              <h5>DRAW in:</h5>
                              <div
                                id="timer-sweepstake-1"
                                className="timer"
                                data-date={moment(
                                  this.state.nextSweepstakeFinishDate
                                )}
                              >
                                <div className="timer-box">
                                  <div className="timer-num days">
                                    <span>0</span>
                                    <span>0</span>
                                  </div>
                                  <p>Days</p>
                                </div>
                                <div className="timer-box">
                                  <div className="timer-num hours">
                                    <span>0</span>
                                    <span>0</span>
                                  </div>
                                  <p>Hours</p>
                                </div>
                                <div className="timer-box">
                                  <div className="timer-num minutes">
                                    <span>0</span>
                                    <span>0</span>
                                  </div>
                                  <p>Minutes</p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="active-prize__bon"
                            id="active-prize-sum"
                          >
                            $PLY {sum}
                            <img src="images/icons/cash-icon.png" alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="cash-bon" id="filteredSweep-bon">
                      {filteredSweep && filteredSweep.firstPrize > 0 && (
                        <div className="cash-prizes__box">
                          <div className="cash-prizes__bonus">
                            <p>
                              {filteredSweep
                                ? "$PLY " + filteredSweep.firstPrize
                                : "$PLY 0"}{" "}
                              <img src="images/icons/cash-icon.png" alt="" />
                            </p>
                            <h5>1° Prize</h5>
                          </div>
                        </div>
                      )}
                      <div className="cash-places">
                        {filteredSweep && filteredSweep.secondPrize > 0 && (
                          <div className="place">
                            <p>
                              {filteredSweep
                                ? "$PLY " + filteredSweep.secondPrize
                                : "$PLY 0"}{" "}
                              <img src="images/icons/cash-icon.png" alt="" />
                            </p>
                            <h5>2°</h5>
                          </div>
                        )}
                        {filteredSweep && filteredSweep.thirdPrize > 0 && (
                          <div className="place">
                            <p>
                              {filteredSweep
                                ? "$PLY " + filteredSweep.thirdPrize
                                : "$PLY 0"}{" "}
                              <img src="images/icons/cash-icon.png" alt="" />
                            </p>
                            <h5>3°</h5>
                          </div>
                        )}
                        {filteredSweep && filteredSweep.fourthPrize > 0 && (
                          <div className="place">
                            <p>
                              {filteredSweep
                                ? "$PLY " + filteredSweep.fourthPrize
                                : "$PLY 0"}{" "}
                              <img src="images/icons/cash-icon.png" alt="" />
                            </p>
                            <h5>4 - 10°</h5>
                          </div>
                        )}
                        {filteredSweep && filteredSweep.fifthPrize > 0 && (
                          <div className="place">
                            <p>
                              {filteredSweep
                                ? "$PLY " + filteredSweep.fifthPrize
                                : "$PLY 0"}{" "}
                              <img src="images/icons/cash-icon.png" alt="" />
                            </p>
                            <h5>11 - 20°</h5>
                          </div>
                        )}
                        {filteredSweep && filteredSweep.sixthPrize > 0 && (
                          <div className="place">
                            <p>
                              {filteredSweep
                                ? "$PLY " + filteredSweep.sixthPrize
                                : "$PLY 0"}
                              <img src="images/icons/cash-icon.png" alt="" />
                            </p>
                            <h5>21 - 50°</h5>
                          </div>
                        )}
                        {filteredSweep && filteredSweep.seventhPrize > 0 && (
                          <div className="place">
                            <p>
                              {filteredSweep
                                ? "$PLY " + filteredSweep.seventhPrize
                                : "$PLY 0"}{" "}
                              <img src="images/icons/cash-icon.png" alt="" />
                            </p>
                            <h5>51 - 100°</h5>
                          </div>
                        )}
                        {filteredSweep && filteredSweep.eighthPrize > 0 && (
                          <div className="place">
                            <p>
                              {filteredSweep
                                ? "$PLY " + filteredSweep.eighthPrize
                                : "$PLY 0"}{" "}
                              <img src="images/icons/cash-icon.png" alt="" />
                            </p>
                            <h5>101 - 200°</h5>
                          </div>
                        )}
                        {filteredSweep && filteredSweep.ninthPrize > 0 && (
                          <div className="place">
                            <p>
                              {filteredSweep
                                ? "$PLY " + filteredSweep.ninthPrize
                                : "$PLY 0"}
                              <img src="images/icons/cash-icon.png" alt="" />
                            </p>
                            <h5>201 - 500°</h5>
                          </div>
                        )}
                        {filteredSweep && filteredSweep.tenthPrize > 0 && (
                          <div className="place">
                            <p>
                              {filteredSweep
                                ? "$PLY " + filteredSweep.tenthPrize
                                : "$PLY 0"}{" "}
                              <img src="images/icons/cash-icon.png" alt="" />
                            </p>
                            <h5>501 - 1000°</h5>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="cash-prizes__bottom">
                      <div className="quick-tip">
                        <h2>QUICK TIP</h2>
                        <p>
                          You can also collect Lucky Coins by playing in
                          tournaments.
                        </p>
                      </div>
                      <div className="popup-text">
                        <p>WHAT ARE YOU WAITING FOR?</p>
                      </div>

                      {localStorage.jwtToken ? (
                        // <Link1 to={"/#all-games"}>
                        <a
                          data-fancybox-close
                          id="play-redirect-sweep"
                          className="btn btn__gradient btn__icon--r"
                        >
                          play NOW <i className="icon-play"></i>
                        </a>
                      ) : (
                        // </Link1>
                        <>
                          {" "}
                          <a
                            className="btn btn__gradient btn__icon--r"
                            id="winners-register"
                          >
                            REGISTER NOW <i className="icon-person_add_alt"></i>
                          </a>
                          <p className="popup-bottom__link">
                            AND GET YOUR WELCOME BONUS
                          </p>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="tab-content">
                    <div className="cash-prizes__row">
                      <div className="cash-prizes__l">
                        <img src="images/other/cash-img-2.png" alt="" />
                      </div>
                      <div className="cash-prizes__r">
                        <div className="cash-prizes__text">
                          <h2>FEELING STRONG?</h2>
                          <h3>COMPETE IN DAILY TOURNAMENTS</h3>
                          <ul>
                            <li>
                              Win $PLY by placing yourself high in the score
                            </li>
                            <li>
                              The highest the score the highest the $PLY reward
                            </li>
                            <li>Multiple positions get paid</li>
                          </ul>
                        </div>
                        <div className="active-prize">
                          <div className="active-prize__text">
                            <h3>ACTIVE JACKPOT</h3>
                          </div>
                          <div className="active-prize__bon" id="tournamentSum">
                            $PLY 0{" "}
                            <img src="images/icons/cash-icon.png" alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="cash-prizes__bottom">
                      <div className="quick-tip">
                        <h2>QUICK TIP</h2>
                        <p>
                          You can also collect Lucky Coins by playing in
                          tournaments.
                        </p>
                      </div>
                      <div className="popup-text">
                        <p>WHAT ARE YOU WAITING FOR?</p>
                      </div>

                      {localStorage.jwtToken ? (
                        // <Link1 to={'#tournament-scroll'}>
                        <a
                          data-fancybox-close
                          id="play-redirect-tour"
                          className="btn btn__gradient btn__icon--r"
                        >
                          play NOW <i className="icon-play "></i>
                        </a>
                      ) : (
                        // </Link1>
                        <>
                          {" "}
                          <a
                            className="btn btn__gradient btn__icon--r"
                            id="winners-register"
                          >
                            REGISTER NOW <i className="icon-person_add_alt"></i>
                          </a>
                          <p className="popup-bottom__link">
                            AND GET YOUR WELCOME BONUS
                          </p>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              id="mywinnings"
              style={{ display: "none" }}
              className="popup-style popup-style__pt popup-style__w600"
            >
              <p>
                <button
                  type="button"
                  data-fancybox-close
                  className="fancybox-close-small fancybox-button"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1"
                    viewBox="0 0 24 24"
                  >
                    <path d="M13 12l5-5-1-1-5 5-5-5-1 1 5 5-5 5 1 1 5-5 5 5 1-1z"></path>
                  </svg>
                </button>
              </p>
              <div className="popup-box">
                <div className="popup-top">
                  <div className="popup-top__img popup-top__img--picture">
                    <img
                      src={
                        user && user.imageUrl
                          ? user.imageUrl
                          : "assets/images/games/defaultProfile.jpg"
                      }
                      alt=""
                    />
                  </div>
                </div>
                <div className="popup-content">
                  <h2 className="popup-nickname">{user ? user.name : ""}</h2>
                  <h3 className="title-results">YOUR WINNINGS</h3>
                  <div
                    className="table-wrap table-wrap__w830"
                    style={{ width: "auto" }}
                  >
                    <div className=" box-highscores__scroll">
                      {Object.keys(winnings).length > 0 &&
                        winnings.map((element, index) => {
                          return (
                            <a
                              key={index}
                              className="my_winning_popup_click"
                              // data-id={element.sweepStakeId
                              //   ? `sweepstake?s_id=${element.sweepStakeId}`
                              //   : `tournamentWinners?t_id=${element.tournamentId}`}
                              href={
                                element.sweepStakeId
                                  ? `sweepstake?s_id=${element.sweepStakeId}`
                                  : `tournamentWinners?t_id=${element.tournamentId}`
                              }
                            >
                              <div key={index} className="winning-t-row">
                                <div className="t-user row-user">
                                  <div
                                    key={index}
                                    className="winning-game-result"
                                    style={{ width: "100%" }}
                                  >
                                    <div className="game-result__img">
                                      <img
                                        src={
                                          element.gameIcon
                                            ? element.gameIcon
                                            : "assets/images/playtoo.png"
                                        }
                                        alt=""
                                      />
                                    </div>
                                    <div className="game-result__info winning-g-name color-white">
                                      <h2>{element.type}</h2>
                                      <p>
                                        {element.gameName
                                          ? element.gameName
                                          : element.name}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                {/* <div className="t-prize">{you.pos}°</div> */}
                                <div className="t-cash">
                                  <div className="my-cash color-white">
                                    <h1>$PLY {element.winning}</h1>{" "}
                                    <img
                                      src="images/icons/cash-icon.png"
                                      alt=""
                                    />
                                  </div>
                                </div>
                              </div>
                            </a>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
      </div>
    );
  }
}

Header.propTypes = {
  // logoutUser: PropTypes.func.isRequired,
  changeNickname: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  user: state.auth ? state.auth.user : null,
  errors: state.error ? state.error.newPassword : null,
  loading: state.profile.loading ? state.profile.loading : null,
  userCoins: state.game ? state.game.userCoins : 0,
  tournaments: state.sweepstake.tournaments ? state.sweepstake.tournaments : [],
  nextSweepstakeDate: state.sweepstake.nextSweepstakeDate
    ? state.sweepstake.nextSweepstakeDate
    : null,
  sweepstake: state.sweepstake.sweepstakes ? state.sweepstake.sweepstakes : [],
  highscores: state.game.userHighscores ? state.game.userHighscores : [],
  winnings: state.game.winnings ? state.game.winnings : {},
  userTournaments: state.tournament.userTournaments
    ? state.tournament.userTournaments
    : [],
  coins: state.game ? state.game.coins : 0,
  t_positions: state.tournament.userPositions
    ? state.tournament.userPositions
    : [],
  t_you: state.tournament.yourPosition ? state.tournament.yourPosition : {},
  tournamentSeen: state.tournament.tournamentSeen
    ? state.tournament.tournamentSeen
    : [],
  s_positions: state.sweepstake.sweepstakeWinners
    ? state.sweepstake.sweepstakeWinners
    : [],
  sweepstakeEnded: state.sweepstake.sweepstakeEnded
    ? state.sweepstake.sweepstakeEnded
    : false,
  tournamentPayload: state.tournament.tournamentPayload
    ? state.tournament.tournamentPayload
    : {},
  tournamentEnded: state.tournament.tournamentEnded
    ? state.tournament.tournamentEnded
    : false,
  router: state.router.location ? state.router.location : [],
  countryAllowed: state.auth.countryAllowed ? state.auth.countryAllowed : false,
  paymentStatus: state.payment.success ? state.payment.success : false,
});

const mapDispatchToProps = (dispatch) => ({
  sweepStakeDispatch: (data) => {
    dispatch(sweepStake(data));
  },
  getAllSweepStakeDispatch: () => {
    dispatch(getAllSweepStake());
  },
  logoutUserDispatch: (data) => {
    dispatch(logoutUser(data));
  },
  changeNickname: (data) => {
    dispatch(changeNickname(data));
  },
  fetchCurrentUserDispatch: () => {
    dispatch(getCurrentUser());
  },
  isAuthenticatedDispatch: () => {
    dispatch(isAuthenticated(true));
  },
  changePasswordDispatch: (data) => {
    dispatch(changePassword(data));
  },
  changeProfileImgDispatch: (data) => {
    dispatch(changeProfileImg(data));
  },
  loginJwtWithWallet: (payload, history) => {
    dispatch(loginJwtWithWallet(payload, history));
  },
  registerWithWallet: (payload, history) => {
    dispatch(registerWithWallet(payload, history));
  },
  fetchTournamentsDispatch: () => {
    dispatch(getTournaments());
  },
  fetchAllTournamentsDispatch: () => {
    dispatch(getAllTournaments());
  },
  getUserCoins: () => {
    dispatch(getUserCoins());
  },
  loginWithFacebookDispatch: (payload, history) => {
    dispatch(loginWithFacebook(payload, history));
  },
  getMonthlySweepStakeWinnersDispatch: () => {
    dispatch(getMonthlySweepStakeWinners());
  },
  getMonthlyTournamentWinnersDispatch: () => {
    dispatch(getMonthlyTournamentWinners());
  },
  getHighscoresDispatch: (userId) => {
    dispatch(getHighscores(userId));
  },
  getWinningsDispatch: (userId) => {
    dispatch(getWinnings(userId));
  },
  getTournamentPositions: (payload) => {
    dispatch(getTournamentPositions(payload));
  },
  updateTournamentSeenDispatch: (payload) => {
    dispatch(updateTournamentSeen(payload));
  },
  getUserSweepstakePosition: (payload) => {
    dispatch(getUserSweepstakePosition(payload));
  },
  getSweepStakeWinner: (payload) => {
    dispatch(getSweepStakeWinner(payload));
  },
  doubleUserCoins: (payload) => {
    dispatch(doubleUserCoins(payload));
  },
  getUserTournamentsDispatch: () => {
    dispatch(getUserTournaments());
  },
  setOtherTournamentEndedDispatch: (bool) => {
    dispatch(setOtherTournamentEnded(bool));
  },
  setUserMoneyDispatch: (num) => {
    dispatch(setUserMoney(num));
  },
  getTournamentSeenDispatch: () => {
    dispatch(getTournamentSeen());
  },
  getloginUserHighscoresDispatch: () => {
    dispatch(getloginUserHighscores());
  },
  updateGamesIoDispatch: (arr) => {
    dispatch(updateGamesIo(arr));
  },
  updateSweepIoDispatch: (arr) => {
    dispatch(updateSweepIo(arr));
  },
  tournamentPosSocketDispatch: (arr) => {
    dispatch(tournamentPosSocket(arr));
  },
  userTScoresUpdateIoDispatch: (arr) => {
    dispatch(userTScoresUpdateIo(arr));
  },
  updateTournamentsIoDispatch: (arr) => {
    dispatch(updateTournamentsIo(arr));
  },
  sweepstakePosSocketDispatch: (arr) => {
    dispatch(sweepstakePosSocket(arr));
  },
  getTransactionHistoryDispatch: () => {
    dispatch(getTransactionHistory());
  },
  cancelTransactionDispatch: () => {
    dispatch(cancelTransaction());
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header));
// export default connect(mapStateToProps, mapDispatchToProps, { logoutUser })(withRouter(Header));
