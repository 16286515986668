import React from "react";
import { connect } from "react-redux";
import "./App.css";
import { Switch, Route } from "react-router-dom";
import setAuthToken from "./utils/setAuthToken";
import OneSignal from 'react-onesignal';
import dashboardRoute from "./components/dashboard/dashboard";
import game3Route from "./components/games/game-3";
import gameTest from "./components/games/game_test";
import Header from "./common/header/header";
import tournamentRoute from "./components/games/tournament";
import adchk from "./components/games/adChecker";
import page1Route from "./components/pages/page-1";
import page2Route from "./components/pages/page-2";
import page3Route from "./components/pages/page-3";
import privacypolicy from "./components/pages/privacypolicy";
import cookiepolicy from "./components/pages/cookiepolicy";
import sweepstakeRules from "./components/pages/sweepstakeRules";
import tournamentRules from "./components/pages/tournamentRules";
import Footer from "./common/footer/footer";
import loginRoute from "./components/auth/login";
import forgetpassRoute from "./components/auth/changePassword";
import signupRoute from "./components/auth/signup";
import Popups from "./components/popups";

if (localStorage.jwtToken) {
  setAuthToken(localStorage.jwtToken);
}

class App extends React.Component {
  componentDidMount(){
    const iD = process.env.REACT_APP_ONESIGNAL_ID
    OneSignal.init({
      appId: iD
    });
    // debugger
    OneSignal.setDefaultNotificationUrl("http://3.231.26.157/");
    OneSignal.setDefaultTitle("Playtoo");
    window.openModale("#beta-testers-modal");
  }
  render() {
    return (
      <div className="App">
        <Header />
        <Switch>
          {/* <Header /> */}
          {/* <Route exact path="/modal" component={modal} /> */}
          <Route exact path="/" component={dashboardRoute} />
          <Route exact path="/sweepstake" component={page2Route} />
          <Route exact path="/winner" component={page1Route} />
          <Route exact path="/tournamentWinners" component={page3Route} />
          <Route exact path="/privacypolicy" component={privacypolicy} />
          <Route exact path="/cookiepolicy" component={cookiepolicy} />
          <Route exact path="/tournamentRules" component={tournamentRules} />
          <Route exact path="/sweepstakeRules" component={sweepstakeRules} />
          <Route exact path="/signin" component={loginRoute} />
          <Route exact path="/forgetpassword" component={forgetpassRoute} />
          <Route exact path="/signup" component={signupRoute} />
          <Route exact path="/popups" component={Popups} />
          <Route exact path="/gameplay" component={game3Route} />
          <Route exact path="/tournament" component={tournamentRoute} />
          <Route exact path="/adCheck" component={adchk} />
          <Route exact path="/gameTest" component={gameTest} />

          {/* <Route exact path="/reset-password" component={ResetPassword} /> */}
        </Switch>
        {this.props.router.pathname === "/gameplay" ||
        this.props.router.pathname === "/tournament" ? (
          ""
        ) : (
          <Footer />
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  router: state.router.location ? state.router.location : [],
});

export default connect(mapStateToProps)(App);
